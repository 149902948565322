import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const Create = lazy(() => import("./pages/Create"));
const Details = lazy(() => import("./pages/Details"));

const routes = [
  /**
   * if not found redirect to homepage or another page
   * change this element to redirect 404 page for the example
   */
  {
    path: "*",
    restricted: false,
    component: Home,
  },
  {
    path: "/",
    restricted: false,
    component: Home,
  },
  {
    path: "/create",
    restricted: false,
    component: Create,
  },
  {
    path: "/details",
    restricted: false,
    component: Details,
  },
];

export default routes;
