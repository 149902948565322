import React from "react";
import { img } from "../assets/images";
import { Link } from "react-router-dom";

const Navbar = () => {

  return (
    <nav className="sticky top-0 z-10 shadow-md">
      <div className="flex justify-between items-center w-full bg-gray-50 p-2 sm:p-3">
        <Link
          to={'/'}>
          <div className='flex items-center rounded-full bg-black py-2'>
            <div className='pl-3'>
              <img src={img.kdnLogo} alt="logo-kdn" className="w-[30px]" />
            </div>
            <div className='text-2xl font-bold text-white pr-3'>KDN</div>
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
