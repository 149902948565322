import { img } from "../assets/images";

const Footer = () => {
  return (
    <>
      <div className="flex flex-col justify-center text-center items-center text-xs md:text-sm bg-black-kdn text-white py-2">
        <div className="flex flex-col items-center">
          {/* tagline and logo */}
          <div className="text-white text-center flex items-center">
            <div>
              <img src={img.kdnLogo} alt="logo-kdn" className="w-[50px]" />
            </div>
            <div>
              <h1 className="text-4xl font-bold">KDN</h1>
            </div>
          </div>
          <p className="mt-1 max-w-xs lg:max-w-3xl text-primary-lime">
            Your Premier Digital Solutions Partner
          </p>
        </div>
        <div className="mt-2">
          <p>
            &copy; Copyright KDN 2024. All rights reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
