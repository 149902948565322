import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import { isLogin } from "./utils";
import 'video.js/dist/video-js.css';
import 'videojs-ima/dist/videojs.ima.css';
import '@videojs/themes/dist/forest/index.css';
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          {routes.map((data, index) =>
            data.component ? (
              <Route
                key={index}
                path={data.path}
                element={
                  <Suspense fallback={<></>}>
                    {data.restricted !== false ? (
                      <ProtectedRoute user={isLogin()}>
                        <data.component />
                      </ProtectedRoute>
                    ) : (
                      <data.component />
                    )}
                  </Suspense>
                }
              />
            ) : null
          )}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
